import React from 'react';
import xhr from 'xhr';

export default class BackendMPK2 extends React.Component {
	static TOKEN_KEY = `oidc.user:https://id.matpriskollen.io:${this.getAuthClientId()}`;

	static getToken() {
		const params = this.getParams();
		if (params.token) {
			sessionStorage.setItem(this.TOKEN_KEY, params.token);
			this.redirectWithoutParam('token', params.token);
		}

		return JSON.parse(sessionStorage.getItem(this.TOKEN_KEY))?.access_token;
	}

	static redirectWithoutParam(key, value) {
		const url = window.location.href.replace(`${key}=${value}`, '');
		//  browserHistory.push(url);
	}

	static getUuid() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			let r = (Math.random() * 16) | 0,
				v = c == 'x' ? r : (r & 0x3) | 0x8;

			return v.toString(16);
		});
	}

	static getParams() {
		const paramsStr = window.location.search || '?';
		const params = {};
		paramsStr
			.slice(1)
			.split('&')
			.forEach((paramStr) => {
				const index = paramStr.indexOf('=');
				const key = paramStr.slice(0, index);
				const value = paramStr.slice(index + 1);
				params[key || ''] = value;
			});

		return params;
	}

	static getAuthClientId() {
		const hostName = window.location.hostname;

		if (['pricesad.matpriskollen.io'].includes(hostName)) {
			return '234882004762559036@matpriskollen_interna_appar';
		}

		// Both localhost and dev uses dev auth
		return '234623347470697020@dev_-_matpriskollen_interna_appar';
	}

	static getBaseURL(useDirectApi) {
		const hostName = window.location.hostname;

		if (['pricesad.matpriskollen.io'].includes(hostName)) {
			return 'https://adminapi.matpriskollen.io/api/';
		}

		return 'https://adminapi.test.matpriskollen.io/api/';
		// return 'http://localhost:5002/api/';
	}

	static request(method, pathUrl, data, useDirectApi) {
		const url = `${this.getBaseURL(useDirectApi)}${pathUrl}`;

		const token = BackendMPK2.getToken();
		const headers = {
			'Content-Type': 'application/json',
			'X-Correlation-ID': this.getUuid(),
		};

		if (token) {
			headers.Authorization = `Bearer ${token}`;
		}

		const req = {
			url: url,
			headers: headers,
			method: method,
		};

		if (data || (!data && typeof data == 'boolean')) {
			req.body = JSON.stringify(data);
		}

		return new Promise((resolve, reject) => {
			xhr(req, (err, response) => {
				if (err) {
					reject(err);
				}

				const statusCode = response.statusCode;

				if (statusCode === 401) {
					localStorage.setItem('redirectUrl', window.location.href);

					return;
				} else if (statusCode >= 400) {
					if (statusCode <= 403 || (statusCode >= 500 && statusCode < 600)) {
						window.location.href = `/error?code=${statusCode}`;
						//  browserHistory.push(`/error?code=${statusCode}`);
					}

					reject(response.statusCode);
				}
				localStorage.setItem('redirectUrl', window.location.href);

				resolve(JSON.parse(response.body));
			});
		});
	}

	static getIcaStoresRequest(url) {
		const headers = {
			'Content-Type': 'application/json',
		};
		const req = {
			url: url,
			headers: headers,
			method: 'get',
		};

		return new Promise((resolve, reject) => {
			xhr(req, (err, response) => {
				if (err) {
					reject(err);
				}
				resolve(JSON.parse(response.body));
			});
		});
	}

	static delete(pathUrl) {
		return this.request('DELETE', pathUrl);
	}

	static get(pathUrl) {
		return this.request('GET', pathUrl);
	}

	static put(pathUrl, data) {
		return this.request('PUT', pathUrl, data);
	}

	static post(pathUrl, data, useDirectApi) {
		return this.request('POST', pathUrl, data, useDirectApi);
	}

	static patch(pathUrl, data) {
		return this.request('PATCH', pathUrl, data);
	}

	static getStatistik() {
		return this.get('statistik');
	}

	static translateStatistik(statistik) {
		return {
			crawlProducts: {
				new: statistik.butikProdukt.nya,
				all: statistik.butikProdukt.totalt,
			},
			images: {
				new: statistik.produktbild.nya,
				all: statistik.produktbild.totalt,
			},
			prices: {
				new: statistik.pris.nya,
				all: statistik.pris.totalt,
			},
			products: {
				new: statistik.produkt.nya,
				all: statistik.produkt.totalt,
			},
			stores: {
				new: statistik.butik.nya,
				all: statistik.butik.totalt,
			},
			unmatched: {
				new: statistik.omatchat.nya,
				all: statistik.omatchat.totalt,
			},
		};
	}

	static fetchCrawls(amount, page) {
		return this.get(`spindling?page=${page || 1}&amount=${amount || 10}`).then((r) => {
			return this.translateCrawls(r);
		});
	}

	static translateCrawls(spindlingar) {
		return spindlingar.map((s) => ({
			id: s._key,
			crawlerId: s.spindelKod,
			crawlerName: s.spindelNamn,
			created: s.startad || s.created,
			updated: s.avslutad || s.uppdaterad || s.updated,
			forloppProcent: s.forloppProcent,
			status: s.status,
			argument: s.argument,
		}));
	}
}
