import { ArrowDownward, ArrowUpward, SwapVert } from '@mui/icons-material';
import { Box, CircularProgress, List, ListItem, ListItemButton, ListSubheader } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import React, { useEffect, useRef, useState } from 'react';
import Utils from '../../services/Utils';
import ContextMenu from '../contextmenu/ContextMenu';
import './ResultList.module.css';
import classes from './ResultList.module.css';

const ResultList = (props) => {
	const scrollTriggerPercentage = 0.3;
	const sortOrderEnum = { none: 0, asc: 1, desc: 2 };
	const scrollableDiv = useRef(null);

	const [sortProductsEnabled, setSortProductsEnabled] = useState(!!props.sortProductsEnabled);
	const [sortOrder, setSortOrder] = useState(sortOrderEnum.none);
	const [directSource, setDirectSource] = useState(props.directSource);

	const scrollToTop = () => {
		if (scrollableDiv.current) {
			scrollableDiv.current.scrollTop = 0;
		}
	};

	useEffect(() => {
		const onScrollEvent = () => {
			if (scrollableDiv.current && props.handleScrollToBottom) {
				const elementHeight = scrollableDiv.current.innerHeight || scrollableDiv.current.offsetHeight;
				const scrollTopTrigger = scrollableDiv.current.scrollTop + elementHeight * scrollTriggerPercentage;

				if (scrollTopTrigger >= scrollableDiv.current.scrollHeight - elementHeight) {
					props.handleScrollToBottom();
				}
			}
		};

		const currentDiv = scrollableDiv.current;
		if (currentDiv) {
			currentDiv.addEventListener('scroll', onScrollEvent);
		}
		return () => {
			if (currentDiv) {
				currentDiv.removeEventListener('scroll', onScrollEvent);
			}
		};
	}, [props]);

	const handleProductSelected = (product) => {
		props.handleProductSelected(product);
	};

	const handleProductImageSelected = (product) => {
		if (props.handleProductImageSelected) {
			props.handleProductImageSelected(product);
		} else {
			props.handleProductSelected(product);
		}
	};

	const renderProgress = (visible) => {
		if (visible) {
			return (
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<CircularProgress className={classes.progress} />
				</Box>
			);
		}
		return null;
	};

	const getSortIcon = () => {
		switch (sortOrder) {
			case sortOrderEnum.none:
				return <SwapVert />;
			case sortOrderEnum.asc:
				return <ArrowUpward />;
			case sortOrderEnum.desc:
				return <ArrowDownward />;
		}
	};

	const renderSubHeader = () => {
		if (props.subheaderVisible) {
			return (
				<ListSubheader>
					{sortProductsEnabled ? (
						<Box
							className={classes.tableHeader}
							style={{ cursor: 'default' }}
							onClick={setNextSortOrder}
						>
							{'Sortera efter namn'}
							{getSortIcon()}
						</Box>
					) : (
						'Sökresultat'
					)}
				</ListSubheader>
			);
		}
		return null;
	};

	const setNextSortOrder = () => {
		const getNextSortOrder = (previousSortOrder) => {
			switch (previousSortOrder) {
				case 0:
					return 1;
				case 1:
					return 2;
				case 2:
					return 0;
			}
		};

		setSortOrder(getNextSortOrder(sortOrder));
	};

	const renderSecondaryText = (product) => {
		const produkt = product.Produkt;
		const amountPerUnit = produkt.mangd / produkt.antal;
		const volume = Utils.formatProductVolume(amountPerUnit, produkt.enhet);
		const infos = [];
		if (produkt.antal > 1 && produkt.enhet !== 3) {
			infos.push(`${produkt.antal} x ${volume}`);
		} else {
			infos.push(volume);
		}
		if (produkt.gtin) {
			infos.push(produkt.gtin);
		}
		if (produkt.extra && produkt.extra.length > 0) {
			infos.push(produkt.extra);
		}

		return (
			<span>
				{infos.join(' - ')} <br />
				{!product.SenastSpindlad ? 'Äldre än 4 månader' : product.SenastSpindlad.substring(0, 10)}
				<span style={{ display: 'none' }}>{produkt._key}</span>
			</span>
		);
	};

	const getSortedProducts = () => {
		const products = (props.products || []).slice();

		switch (sortOrder) {
			case sortOrderEnum.none:
				return products;
			case sortOrderEnum.asc:
				return products.sort((a, b) => a.Produkt.namn.localeCompare(b.name, 'sv-SE'));
			case sortOrderEnum.desc:
				return products.sort((a, b) => b.Produkt.namn.localeCompare(a.name, 'sv-SE'));
		}
	};

	const secondLineRows = (product) => {
		let lines = 2;
		if (product.Produkt.extra) {
			lines++;
		}

		return Math.min(2, lines);
	};

	const products = getSortedProducts();
	const progressVisible = props.progressVisible;
	const emptyStateText = props.emptyStateText;
	const max = props.maxHeight || '';

	let selectedIndex = props.selectedIndex || null;

	return (
		<Box
			ref={scrollableDiv}
			style={{ height: 'auto', overflowY: 'auto' }}
		>
			<List
				id="resultlist"
				sx={{ maxHeight: max }}
			>
				{renderSubHeader()}

				{!products.length && <Box className={classes.noResults}>{emptyStateText}</Box>}

				{renderProgress(progressVisible)}

				{products.map((product, index) => (
					<ListItemButton
						selected={selectedIndex === index}
						onClick={(event) => {
							selectedIndex = index;
							handleProductSelected(products[index]);
						}}
						key={product.Produkt._key}
					>
						<ListItem
							alignItems="center"
							id={product.Produkt._key}
							data-tip
							data-for={product.Produkt._key}
							data-produkt-typ={product.Produkt.produktTyp}
							style={product.Produkt.synlig === false ? { backgroundColor: '#ffb9c2' } : {}}
							value={index}
						>
							<Box
								className={classes.productImageHolder}
								onClick={(evt) => {
									evt.stopPropagation();
									selectedIndex = index;
									handleProductImageSelected(products[index]);
								}}
							>
								<img
									className={classes.productImage}
									src={
										(product.Produkt.produktBildThumbnail && product.Produkt.produktBildThumbnail.url) ||
										product.Produkt.bildUrl ||
										''
									}
								/>
							</Box>

							<ListItemText
								className={classes.productSearchText}
								primary={product.Produkt.namn}
								sx={{
									display: 'flex',
									padding: 0,
									flexDirection: 'column',
								}}
								secondary={
									<React.Fragment>
										{renderSecondaryText(product)} <br />
										{product.Varumarken && product.Varumarken.length > 0 ? product.Varumarken[0].namn : ''}
									</React.Fragment>
								}
							/>
						</ListItem>
					</ListItemButton>
				))}
			</List>
			<ContextMenu />
		</Box>
	);
};

export default ResultList;
